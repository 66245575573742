import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/default-page-layout.js";
import PageTitle from '../components/PageTitle';
import LesneZaloge from '../components/LesneZaloge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle mdxType="PageTitle">Zelena energija</PageTitle>
    <h2>{`Projektiramo, financiramo in izvajamo projekte prehoda na zelene vire energije v stanovanjskih, javnih in poslovnih objektih.`}</h2>
    <p>{`Ker želimo biti odgovorni do okolja in naših otrok, našim članom dobavljamo oz. omogočamo dobavo izključno zelene energije, to je energije iz obnovljivih virov. Prvenstveno se osredotočamo na `}</p>
    <ul>
      <li parentName="ul">{`lesno biomaso in `}</li>
      <li parentName="ul">{`vodne vire,`}</li>
    </ul>
    <p>{`saj sta ta dva vira najpomembnejši naravni bogastvi Slovenije. `}</p>
    <h2>{`Lesno biomaso uporabljamo za ogrevanje in soproizvodnjo toplote in elektrike.`}</h2>
    <p>{`Ko gre za ogrevanje, nadomestimo stare peči – običajno na nafto – s sodobnimi pečmi na lesne sekance ali pelete. Investicijo financira Sončna zadruga, poskrbimo tudi za pridobitev možnih državnih subvencij. Lastniku objekta pa potem po dogovorjeni ceni in za dogovorjeno število let dobavljamo toploto, ki je cenejša od toplote, pridobljene iz fosilnih goriv.`}</p>
    <h2>{`Pri proizvodnji elektrike v kogeneracijah na lesno biomaso ali v malih hidroelektrarnah`}</h2>
    <p>{`investicijo prav tako izvedemo sami, z lokalnimi lastniki zemljišč oz. lokalnimi dobavitelji lesne biomase pa se dogovorimo za delitev prihodkov od prodane elektrike.`}</p>
    <LesneZaloge mdxType="LesneZaloge" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      